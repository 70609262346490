import type { ComponentPropsWithoutRef, PropsWithChildren } from "react";

import { cn } from "~/utils/classnames";

type WrapperProps = PropsWithChildren<ComponentPropsWithoutRef<"div">>;

export function Wrapper({ children, className }: WrapperProps) {
  return (
    <div
      className={cn(
        "mx-auto w-full max-w-[1464px] px-4 sm:px-5 md:px-6 lg:px-7 xl:px-8",
        className
      )}
    >
      {children}
    </div>
  );
}

export function WrapperRemover({ children, className }: WrapperProps) {
  return (
    <div className={cn("-mx-4 sm:-mx-5 md:-mx-6 lg:-mx-7 xl:-mx-8", className)}>{children}</div>
  );
}
